import React from 'react';
import './Experience.css';
import { MenuItems } from './MenuItems';

class Experience extends React.Component{
    render(){
        return(
            <div className="ExperienceItems" id="experience">
                <h1 className='exp-header'>Experience</h1>
                <ul className = 'exp-list'>
                    {MenuItems.map((item, index) => {
                        return(
                            <li key={index}>
                                <img className='exp-img' src={item.img} alt='img'/>
                                <div className='exp-item'>
                                    <h2 className='exp-item-title'>{item.title}</h2>
                                    <p className='exp-item-desc'>{item.desc}</p>
                                    <p className='exp-item-time'>{item.time}</p>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    }
}

export default Experience