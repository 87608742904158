export const MenuItems = [{
        title: 'Home',
        url: '#/',
        cName: 'nav-links'
    },
    {
        title: 'Biography',
        url: '#biography',
        cName: 'nav-links'
    },
    {
        title: 'Skills',
        url: '#skills',
        cName: 'nav-links'
    },
    {
        title: 'Experience',
        url: '#experience',
        cName: 'nav-links'
    },
    {
        title: 'Projects',
        url: '#projects',
        cName: 'nav-links'
    },
    {
        title: 'Contact',
        url: '#contact',
        cName: 'nav-links'
    }
]